<template>
  <div class="container">
    <!-- 页头 -->
    <img 
      class="header" 
      src="https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/018a2ace-429a-b42b-822a-aee3fc54f370?x-oss-process=style/origin" 
      alt="cover-item"
    >
    <!-- 活动列表 -->
    <main>
      <img 
        v-for="feed in feeds" 
        :key="feed.campaignId" 
        class="feeds" 
        :src="feed.cover" alt="feeds"
        @click="handleToDetail(feed.campaignId)"
      >
    </main>
    <!-- 页脚 -->
    <img 
      class="footer" 
      src="https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/018a2ac5-1d5a-e615-80c7-77937241e2cb?x-oss-process=style/origin" 
      alt="cover-item"
    >
    <!-- 刘海屏横条高度 -->
    <div class="lhp"></div>
  </div> 
</template>

<script>
export default {
  name: 'athletic',
  data() {
    return {
      feeds: [
        {
          "campaignId": "018a1cdb-8ce7-9c25-10db-78d8e648130e",
          "cover": "https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/018a2ac5-1d4b-5f7a-800a-278b596b91c2?x-oss-process=style/origin",
        },
        {
          "campaignId": "018a265f-9158-ba22-f460-22842093220a",
          "cover": "https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/018a2ac5-1d4d-c784-1d6d-e8934861011e?x-oss-process=style/origin",
        },
        {
          "campaignId": "018a202a-1f1a-1a7c-22a9-db7b207a7af9",
          "cover": "https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/018a2ac5-1d4d-8482-1a9e-71976aa99c9e?x-oss-process=style/origin",
        },
        {
          "campaignId": "018a2671-0ca2-d28f-778a-edcc4f74d831",
          "cover": "https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/018a2ac5-1d4b-9ed1-c330-521bc45c4de6?x-oss-process=style/origin",
        }
      ]
    }
  },
  beforeCreate() {
    document.title = "2023中国田径大众达标系列赛(华南大区赛)";
  },
  methods: {
    /** 前往活动详情 */ 
    handleToDetail(campaignId) {
      wx.miniProgram.navigateTo({ url: `/packages/details/pages/index/index?campaignId=${campaignId}` });
    },
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/global.scss";

  .container {
    position: relative;
    width: 100%;
    background-color: #045FA5;

    main {
      @include column(flex-start, center);
      width: 100%;

      .feeds {
        display: block;
        width: px2rem(702);
        height: px2rem(382);
        margin-bottom: px2rem(24);
      }
    }

    .header, 
    .footer {
      display: block;
      width: 100%;
    }

    .lhp {
      width: 100%;
      height: px2rem(34);
    }
  }
</style>
